import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";

export const EditPurchasePlan = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113530", defaultMessage: "Purchase Plan" })
  );
  const history = useHistory();

  const [state, setState] = useState(true);
  const [item, setItem] = useState(null);
  const [listData, setListData] = useState([]);
  const [maxIndex, setMaxIndex] = useState(0);
  const [qty, setQty] = useState(null);
  const [description, setDescription] = useState('')
  const [listMaterial, setListMaterial] = useState([]);

  const [listUnit, setListUnit] = useState([]);
  const [unit, setUnit] = useState(null)
  // const [isAdd, setIsAdd] = useState(false);

  const handleChangeItem = (event, values) => {
    setItem(values);
    if (values) {
      // แปลง major_unit และ minor_unit จาก item ที่เลือก
      const units = [
        { id: values.major_unit_id, name: values.major_unit_name },
        { id: values.minor_unit_id, name: values.minor_unit_name },
      ];
      setListUnit(units);
      setUnit(null); // ล้างค่า unit เมื่อเปลี่ยน item
    } else {
      setListUnit([]);
      setUnit(null);
    }
  };

  const handleChangeUnit = (event, values) => {
    setUnit(values); // Store the entire unit object
  };

  useEffect(() => {
    getListMaterial();
    getPlan();
    getPlanItems();
  }, []);

  const getPlan = async () => {
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: `${baseURL}/purchase/plan?filter=${props.location.state.row.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
            setDescription(res.data.data.name);
        }
      })
      .catch((err) => {});
  };

  
  const getPlanItems = async () => {
    try {
      let token = localStorage.getItem("Token");
      const response = await axios.get(
        `${baseURL}/purchase/plan?id=${props.location.state.row.id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data?.data) {
        // เพิ่ม id ให้กับรายการที่ fetch มา (หากไม่มี id)
        const updatedData = response.data.data.map((item, index) => ({
          ...item,
          id: item.id || index + 1, // ใช้ index ในกรณีที่ไม่มี id
        }));

        setListData(updatedData);

        // ตั้งค่า maxIndex ให้เป็นค่าที่มากที่สุดจาก id ที่มี
        const highestId = Math.max(...updatedData.map((item) => item.id));
        setMaxIndex(highestId);
        console.log("xxxxxxx listData in getPlanItems ::::::", listData)
      } else {
        Swal.fire("Warning", "No data found for the selected plan.", "warning");
      }
    } catch (error) {
      console.error("Error fetching plan items:", error);
      Swal.fire("Error", "Unable to fetch plan items. Please try again.", "error");
    }
  };

  useEffect(() => {
    console.log("xxxxxxx listData in useEffect ::::::", listData)
  }, [listData]);

  const getListMaterial = async () => {
    let token = localStorage.getItem("Token");
    try {
      const res = await axios({
        method: "get",
        url: `${baseURL}/inventory/material?search=&page=1&limit=1000`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data && res.data.data) {
        setListMaterial(res.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      dataField: "material_id",
      text: intl.formatMessage({ id: "113271", defaultMessage: "ID" }),
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "113138", defaultMessage: "Name" }),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "113165", defaultMessage: "Quantity" }),
    },
    {
      dataField: "unit_name",
      text: intl.formatMessage({ id: "113218", defaultMessage: "unit" }),
    },
    {
      text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
      dataField: "id",
      formatter: (id, row) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem(row.id)}
            >
              {intl.formatMessage({ id: "107011", defaultMessage: "delete" })}
            </button>
          </div>
        );
      },
    },
  ];

  const deleteItem = (id) => {
    console.log("Before deletion:", listData);
    setListData((prevData) => {
      const updatedList = prevData.filter((item) => item.id !== id);
      console.log("After deletion:", updatedList);
      if (updatedList.length !== prevData.length) {
        Swal.fire("Deleted", "The item has been removed.", "success");
      }
      return updatedList;
    });
  };

  const handleAdd = () => {
    if (!item || !qty || !unit) {
      Swal.fire("Error", "Please select an item, quantity, and unit before adding.", "error");
      return;
    }

    setListData((prevData) => {
      // Calculate the new ID based on the previous data or the maxIndex
      const newId = Math.max(...prevData.map((item) => item.id || 0), maxIndex) + 1;

      const newItem = {
        id: newId,  // Use the new ID
        name: item.name,
        material_id: item.id,
        quantity: qty,
        unit_id: unit.id,
        unit_name: unit.name
      };

      // Update maxIndex for the next item
      setMaxIndex(newId);

      return [...prevData, newItem];
    });

    setQty("");
    setItem(null);
    setUnit(null);
    console.log("xxxxxxx listData in handleAdd::::::", listData)
  };

  const AlertError = (message) => {
    Swal.fire("Create Delivery Note!", message, "error");
  };
  const handleChangeSwitch = () => {
    setState(!state);
  };

  // const handleSend = async () => {
  //   /*if (description === undefined || description === "") {
  //     return AlertError("Please insert description !!");
  //   }*/
  //   let get_shop_id = localStorage.getItem("shop_id");
  //   let get_branch_id = localStorage.getItem("branch_id");
  //   let now= new Date()
  //   var events = {
  //       id:props.location.state.row.id,
  //       name:description,
  //     orders: listData,
  // }

  //   let apiUrls = `${baseURL}/purchase/plan`;
  //   axios({
  //     method: "put",
  //     url: apiUrls,
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //     data: events,
  //   })
  //     .then(async (res) => {
  //       Swal.fire("Save !", "Save Create Plan success", "success");
  //       history.goBack();
  //     })
  //     .catch((err) => {
  //       Swal.fire(
  //         "Created !",
  //         "Your can not Create Plan . !! " + err,
  //         "error"
  //       );
  //     });
  // };

  const handleSend = async () => {
    const shopId = localStorage.getItem("shop_id");
    const branchId = localStorage.getItem("branch_id");

    const events = {
      id: props.location.state.row.id,
      name: description,
      orders: listData,
    };

    const apiUrls = `${baseURL}/purchase/plan`;

    try {
      const response = await axios.put(apiUrls, events, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      // Optional: Log response for debugging
      console.log("Response data:", response.data);
      Swal.fire({
        title: "Success!",
        text: "Plan successfully created/updated.",
        icon: "success",
      });

      history.goBack();
    } catch (err) {
      console.error("Error while sending data:", err);
      Swal.fire({
        title: "Error!",
        text:
          "Your request to create/update the plan failed. " +
          (err?.response?.data?.system_response?.message || err.message),
        icon: "error",
      });
    }
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({ id: "113532", defaultMessage: "Edit Purchase Plan", })}
        ></CardHeader>
        <CardBody>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({ id: "113138", defaultMessage: "Name" })}:{" "}
              </p>
              <input
                name="table_name"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={description}
                onChange={(table_name) => setDescription(table_name.target.value)}
              />
            </div>
          </div>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113166",
                  defaultMessage: "Item",
                })}
                :{" "}
              </p>
              <div>
                <Autocomplete
                  id="grouped-unit"
                  options={listMaterial}
                  // freeSolo
                  disableClearable
                  getOptionLabel={(option) => `${option.name}`}
                  value={item}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    // borderWidth: "0",
                  }}
                  onChange={handleChangeItem}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113165",
                  defaultMessage: "Amount",
                })}
                :{" "}
              </p>
              <input
                value={qty}
                type="number"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  // borderWidth: "0",
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  // อนุญาตเฉพาะตัวเลข หรือเว้นว่าง
                  setQty(value === "" ? "" : parseFloat(value));
                }}
              />
            </div>
          </div>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113218",
                  defaultMessage: "Unit",
                })}
                :{" "}
              </p>
              <Autocomplete
                id="grouped-unit"
                options={listUnit}
                disableClearable
                getOptionLabel={(option) => `${option.name}`}
                value={unit ? { name: unit.name } : null}
                style={{
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={handleChangeUnit}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div>
          </div>

          <div className="container">
            <div className="form-inline d-flex justify-content-center"
            >
              <button className="btn btn-primary" onClick={handleAdd}>
                {intl.formatMessage({ id: "113167", defaultMessage: "Add" })}
              </button>
            </div>
          </div>

        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <BootstrapTable
            keyField="id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
          <div className="container">
            <div className="form-inline d-flex justify-content-center"
            >
              <Link to="/PurchasePlan">
                <button type="button" className="btn btn-secondary wbt">
                  {intl.formatMessage({
                    id: "107016",
                    defaultMessage: "Cancel",
                  })}
                </button>
              </Link>
              <button className="btn btn-primary" onClick={() => handleSend()}>
                {intl.formatMessage({ id: "107017", defaultMessage: "Save" })}
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
