import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../../../service/API.js";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";

export const EditUnitConvert = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113226", defaultMessage: "Unit Convert Management" })
  );
  const history = useHistory();
  const [data, setData] = useState([]);
  const [main_unit_id, setMain_unit_id] = useState();
  const [main_unit_value, setMain_unit_value] = useState(null);
  const [sub_unit_id, setSub_unit_id] = useState();
  const [sub_unit_value, setSub_unit_value] = useState(null);
  const [qty, setQty] = useState(1);
  const [name, setName] = useState(null);
  const [nameEN, setNameEN] = useState(null);
  const [description, setDescription] = useState(null);
  const [price_per_main_unit, setPrice_per_main_unit] = useState(null);
  const [role, setRole] = useState("");
  const [shop, setShop] = useState([]);
  const [listUnit, setListUnit] = useState([]);

  const getListConvert = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material_unit?time=1&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res.data)
        if (res.message) {
        } else {
          console.log(props.location.state.row.sub_unit.id)
          console.log(props.location.state.row.main_unit.id)
          setListUnit(res.data.data);
          let main = res.data.data.filter(x=> x.id == props.location.state.row.main_unit.id)
          console.log(main)
          let sub = res.data.data.filter(x=> x.id == props.location.state.row.sub_unit.id)
          console.log(sub)
          setMain_unit_value(main[0])
          setSub_unit_value(sub[0])
          setMain_unit_id(props.location.state.row.main_unit.id)
          setSub_unit_id(props.location.state.row.sub_unit.id)
          setQty(props.location.state.row.qty_out_used)
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    console.log(props)
    getListConvert()
  }, []);

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  //'TEXTBOX','DDL','CHECKBOX','IMAGE'
  const onSubmit = async () => {
    console.log(main_unit_id, sub_unit_id, qty)
    
    if (main_unit_id == undefined || main_unit_id == "" || main_unit_id == null) {
      return AlertError("Please insert main unit");
    }
    if (sub_unit_id == undefined || sub_unit_id == "" || sub_unit_id == null) {
      return AlertError("Please insert sub unit");
    }
    if (qty == undefined || qty == "" || qty == null) {
      return AlertError("Please insert quantity");
    }
    const shop_id = localStorage.getItem('shop_id')
    const branch_id = localStorage.getItem('branch_id')

    var events = {
      main_unit_id: main_unit_id,
      sub_unit_id: sub_unit_id,
      qty_out_used: qty,
      shop_id: shop_id,
      branch_id:branch_id,
      
    };
    //let apiUrls = `${baseURL}/management/stock/material_unit_convert/`;
    let apiUrls = `${baseURL}/management/stock/unitconvert/${props.location.state.row.id}`;
    let token = localStorage.getItem("Token");
    console.log(events)
    Swal.fire({
      title: "",
      text: "Confirm Create",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "PUT",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: events,
        })
          .then(async (res) => {
            history.goBack();
          })
          .catch((err) => {
            Swal.fire("Create !", "Your can not create. !! " + err, "error");
          });
      }
    });

    return false;
  };

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113284",
            defaultMessage: "Edit unit convert",
          })}
        >
          <CardHeaderToolbar>
            {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
        <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113227",
                defaultMessage: "Main unit",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              id="grouped-demo"
              options={listUnit}
              value={main_unit_value}
              getOptionLabel={(option) => option.name}
              //getOptionSelected={(option, value) => option.key == value.key}
              //value={item.groupName}
              onChange={(event, newValue) => {
                console.log(newValue);
                setMain_unit_id(newValue.id);
                setMain_unit_value(newValue)
                //updateFieldChanged(index, newValue, 'permission')
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113228",
                defaultMessage: "Sub unit",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              id="grouped-demo"
              options={listUnit}
              
              getOptionLabel={(option) => option.name}
              //getOptionSelected={(option, value) => option.key == value.key}
              //value={item.groupName}
              onChange={(event, newValue) => {
                console.log(newValue);
                setSub_unit_id(newValue.id);
                setSub_unit_value(newValue)
                //updateFieldChanged(index, newValue, 'permission')
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={sub_unit_value}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113502", defaultMessage: "Multiplier" })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              type="number"
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={qty}
              min={0}
              // disabled
              onChange={(e) => setQty(e.target.value)}
            />
          </div>

        
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/UnitConvert">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113245", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "112044", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
