import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { roleCheck } from '../service/role';
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import * as XLSX from 'xlsx'
// import * as XLSXStyle from 'xlsx-style'; 

export { DatePickerField } from "../../_metronic/_partials/controls/forms/DatePickerField.js";

// export const WasteReport = () => {
//     const intl = useIntl();
//     const suhbeader = useSubheader();
//     const allData = [];
//     const [settingPrinterZone, setSettingPrinterZoneData] = useState([]);
//     const [page, setPage] = useState(1);
//     const [totalPage, setTotalPage] = useState(1);
//     const [limit, setLimit] = useState(10);
//     const [totalSize, setTotalSize] = useState(100);
//     suhbeader.setTitle(intl.formatMessage({ id: "113396" ,defaultMessage:"Waste Report"}));
//     const { authToken } = useSelector((state) => state.auth);
//     const [campaigns_id_headder, setCampaigns_id_headder] = useState();
//     const [roleList, setRoleList] = useState([]);

//     const history = useHistory();
//     useEffect(() => {
//         localStorage.removeItem('edit')        
//         checkAuth();

//     }, []);
//     useEffect(()=>{
//         setSettingPrinterZoneData(prev=>prev)
//      },[settingPrinterZone]);
//     useEffect(() => {
//         const savedPage = localStorage.getItem("pagePrinterZone");
//         if (savedPage) {
//             setPage(Number(savedPage)); // ตั้งค่าหน้าจาก localStorage
//         }
//     }, []);
//     const checkAuth =async()=> {
//         const data = await roleCheck('DELIVERY_NOTE')
//         setRoleList(data)
//         console.log(data)
//         if (!data.includes('VIEW')) {
//           history.push('/auth/login')
//         }
//         let value = 1
//         if(localStorage.getItem("pagePrinterZone") != null){
//             value = localStorage.getItem("pagePrinterZone")
//         }
//         let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
//         setCampaigns_id_headder(campaigns_id_headders);
//         getList(value, 10, search, campaigns_id_headders);

//       }

//     // const getList = async (page, limit, search, campaigns_id_headders) => {
//     //     setPage(page);
//     //     axios({
//     //         method: "get",
//     //         // url: `${baseURL}/inventory/wastereport?page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
//     //         //     }`,
//     //         url: `${baseURL}/inventory/wastereport?page=1&limit=10
//     //             `,
//     //         headers: {
//     //             Accept: "application/json",
//     //             Authorization: `Bearer ${authToken}`,
//     //         },
//     //     }).then((res) => {
//     //         if (res.message) {
//     //             history.push("/logout");
//     //         } else {
//     //             console.log("**** res.data ****", res.data)
//     //             console.log("**** res.data.data ****", res.data.data)
//     //             res.data.data.map((data, index) => {
//     //                 data = {
//     //                     id: data.id,
//     //                     item:data.name,
//     //                     amount:data.amount,
//     //                     unit:data.unit,
//     //                     created_at:data.created_at
//     //                 };
//     //                 allData.push(data);
//     //             });
//     //         }

//     //         var totalpage = Math.ceil(res.data.total / res.data.limit);
//     //         setTotalPage(totalpage);
//     //         setTotalSize(res.data.total);
//     //         setLimit(res.data.limit);
//     //         setSettingPrinterZoneData(allData);

//     //     });
//     //     /* var mockdata = {
//     //         id: 1,
//     //         item:'ปูอัด',
//     //         amount:'10',
//     //         unit:'แพค',
//     //         created_at:'10-02-2025 10:00:00:53'
//     //     };

//     //     allData.push(mockdata); */

//     // };
//     const getList = async (page, limit, search, campaigns_id_headders) => {
//         setPage(page);  // ตั้งค่า page ก่อน
//         axios({
//             method: "get",
//             url: `${baseURL}/inventory/wastereport?page=${page}&limit=${limit}${search ? "&search=" + search : ""}`,
//             headers: {
//                 Accept: "application/json",
//                 Authorization: `Bearer ${authToken}`,
//             },
//         }).then((res) => {
//             if (res.message) {
//                 history.push("/logout");
//             } else {
//                 const allData = res.data.data.map((data) => ({
//                     id: data.id,
//                     item: data.name,
//                     amount: data.amount,
//                     unit: data.unit,
//                     created_at: data.created_at
//                 }));

//                 const totalPage = Math.ceil(res.data.total / res.data.limit);
//                 setTotalPage(totalPage);
//                 setTotalSize(res.data.total);
//                 setSettingPrinterZoneData(allData);
//             }
//         });
//     };

//     const columns = [
//         {
//             dataField: "id",
//             text: "ID",
//             sort: true,
//             sortCaret: sortCaret,
//             headerSortingClasses,
//         },

//         {
//             dataField: "item",
//             text: "Item",
//             sort: true,
//             sortCaret: sortCaret,
//             headerSortingClasses,
//         },

//         {
//             dataField: "amount",
//             text: "Amount",
//             sort: true,
//             sortCaret: sortCaret,
//             headerSortingClasses,
//         },

//         {
//             dataField: "unit",
//             text:'Unit',
//             sort: true,
//             sortCaret: sortCaret,
//             headerSortingClasses,
//         },

//         {
//             dataField: "created_at",
//             text: 'created at',
//             sort: true,
//             sortCaret: sortCaret,
//             headerSortingClasses,
//         },
//         // {
//         //     dataField: "created_at",
//         //     text: "Create Date",
//         //     sort: true,
//         //     sortCaret: sortCaret,
//         //     headerSortingClasses,
//         // },
//     ];

//     const [search, setSearch] = useState();
//     const handleSearch = () => {
//         setPage(1);
//         getList(page, limit, search, campaigns_id_headder);
//     };

//     // const handlePageChange = (event, value) => {
//     //     setPage(value);
//     //     localStorage.setItem("pagePrinterZone", value)
//     //     getList(value, limit, search, campaigns_id_headder);
//     // };
//     const handlePageChange = (event, value) => {
//         setPage(value);
//         localStorage.setItem("pagePrinterZone", value); // เก็บค่า page ใหม่ใน localStorage
//         getList(value, limit, search, campaigns_id_headder); // เรียก getList พร้อม page ใหม่
//     };

//     const handlePageSizeChange = (event) => {
//         setLimit(event.target.value);
//         getList(page, event.target.value, search, campaigns_id_headder);
//     };

//     return (
//         <Card>
//             <CardHeader title={intl.formatMessage({ id: "113396",defaultMessage:"Waste Report" })}>

//             </CardHeader>
//             <CardBody>
//                 <div className="row form-inline mb-2">
//                     <div className="col-lg-4 col-md-4 col-sm-4">
//                         <div className="form-group">
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 placeholder={intl.formatMessage({ id: "107002" ,defaultMessage:"Text Search"})}
//                                 style={{ width: "100%" }}
//                                 onChange={(e) => setSearch(e.target.value)}
//                             />
//                         </div>
//                     </div>
//                     <div className="col-lg-2 col-md-2 col-sm-2">
//                         <div className="form-group">
//                             <button
//                                 type="button"
//                                 id="button"
//                                 className="btn btn-primary"
//                                 style={{ width: "100%" }}
//                                 onClick={() => handleSearch()}
//                             >
//                                 {intl.formatMessage({ id: "107003",defaultMessage:"search" })}
//               </button>
//                         </div>
//                     </div>
//                 </div>

//                 <BootstrapTable
//                     keyField="id"
//                     data={settingPrinterZone}
//                     columns={columns}
//                     wrapperClasses="table-responsive"
//                     bordered={false}
//                     classes="table table-head-custom table-vertical-center overflow-hidden"
//                     bootstrap4
//                 >
//                     <PleaseWaitMessage entities={settingPrinterZone} />
//                     <NoRecordsFoundMessage entities={settingPrinterZone} />
//                 </BootstrapTable>
//                 <div className=" col-lg-12">
//                     <div className="row">
//                         <div className="col-lg-6">
//                             <div className="mt-3">
//                                 {intl.formatMessage({ id: "107032",defaultMessage:"Item per page" })}
//                                 <select onChange={handlePageSizeChange} value={limit}>
//                                     <option value={10}>10</option>
//                                     <option value={25}>25</option>
//                                     <option value={50}>50</option>
//                                     <option value={100}>100</option>
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="col-lg-6">
//                             <Pagination2
//                                 className="mt-3 "
//                                 count={totalPage}
//                                 page={localStorage.getItem("pagePrinterZone") != null ? Number(localStorage.getItem("pagePrinterZone")) : page}
//                                 siblingCount={1}
//                                 boundaryCount={1}
//                                 variant="outlined"
//                                 shape="rounded"
//                                 onChange={handlePageChange}
//                             />
//                         </div>
//                     </div>
//                 </div>
//             </CardBody>
//         </Card>
//     );
// };

// const useStyles = makeStyles(() => ({
//     inputField: {
//         backgroundColor: '#F4F6F9',
//         borderRadius: '5px',
//         '& .MuiOutlinedInput-notchedOutline': {
//             border: 'none', // เอา border ออก
//         },
//     },
// }));

export const WasteReport = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const [settingPrinterZone, setSettingPrinterZoneData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const { authToken } = useSelector((state) => state.auth);
    const [campaigns_id_headder, setCampaigns_id_headder] = useState();
    const [roleList, setRoleList] = useState([]);
    const [loading, setLoading] = useState(false)

    const exportExcel = () => {
        // console.log("Exporting Excel...");
        try {
            setLoading(true);

            // const _data = null; // Or undefined
            // if (!_data) throw new Error("No data available for export");

            const _data = settingPrinterZone;

            const headers = ['ID', 'Item', 'Amount', 'Unit', 'Created At', 'Reason'];
            const rows = [];

            const headerFont = { name: 'Arial', sz: 12, bold: true };
            const dataFont = { name: 'Arial', sz: 10 };

            _data.forEach(item => {
                const row = [
                    { v: item.id, s: { font: dataFont, alignment: { horizontal: 'left', vertical: 'center' } } },
                    { v: item.item, s: { font: dataFont, alignment: { horizontal: 'left', vertical: 'center' } } },
                    { v: item.amount, s: { font: dataFont, alignment: { horizontal: 'left', vertical: 'center' } } },
                    { v: item.unit, s: { font: dataFont, alignment: { horizontal: 'left', vertical: 'center' } } },
                    { v: item.created_at ? moment(item.created_at).format('YYYY-MM-DD HH:mm:ss') : '', s: { font: dataFont, alignment: { horizontal: 'left', vertical: 'center' } } },
                    { v: item.reason, s: { font: dataFont, alignment: { horizontal: 'left', vertical: 'center' } } },
                ];
                rows.push(row);
            });

            const datas = [
                headers.map(header => ({ v: header, s: { font: headerFont, alignment: { horizontal: 'center', vertical: 'center' } } })),
                ...rows,
            ];

            const colWidths = headers.map((header, index) => {
                let maxLength = header.length;
                _data.forEach(item => {
                    const value = item[Object.keys(item)[index]];
                    const length = value ? value.toString().length : 0;
                    maxLength = Math.max(maxLength, length);
                });
                return { wch: maxLength + 2 };
            });

            const fileName = `waste-report-${moment(dateFrom).format('YYYY-MM-DD')}-${moment(dateTo).format('YYYY-MM-DD')}.xlsx`;

            const ws = XLSX.utils.aoa_to_sheet(datas);

            ws['!cols'] = colWidths;

            // // Set alignment for header (left align)
            // const range = XLSX.utils.decode_range(ws['!ref']);
            // for (let col = range.s.c; col <= range.e.c; col++) {
            //     const colLetter = XLSX.utils.encode_col(col);
            //     // Apply left alignment to header cells
            //     ws[colLetter + '1'].s = { alignment: { horizontal: 'left' } };
            // }

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            // XLSXStyle.utils.book_append_sheet(wb, ws, 'Sheet1');

            XLSX.writeFile(wb, fileName);
            // XLSXStyle.writeFile(wb, fileName);
        } catch (error) {
            console.log("Error exporting Excel:", error);
        } finally {
            setTimeout(() => {
                setLoading(false);
                // console.log("Finally Exporting Excel...");
            }, 1000);
        }
    };

    var d = new Date()
    d.setDate(d.getDate() - 30)
    const [dateFrom, setDateFrom] = useState(moment(d).format('YYYY-MM-DD'))
    const [dateTo, setDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'))
    suhbeader.setTitle(intl.formatMessage({ id: "113396", defaultMessage: "Waste Report" }))

    const history = useHistory();

    useEffect(() => {
        localStorage.removeItem('edit');
        checkAuth();
    }, []);

    useEffect(() => {
        const savedPage = localStorage.getItem("pagePrinterZone");
        if (savedPage) {
            setPage(Number(savedPage)); // ตั้งค่าหน้าจาก localStorage
        }
    }, []);

    const checkAuth = async () => {
        const data = await roleCheck('DELIVERY_NOTE');
        setRoleList(data);
        if (!data.includes('VIEW')) {
            history.push('/auth/login');
        }

        let campaigns_id_headders = localStorage.getItem("campaigns_id_headder");
        setCampaigns_id_headder(campaigns_id_headders);
        getList(page, limit, search, campaigns_id_headders);
    };

    const getList = async (page, limit, search, campaigns_id_headders) => {
        setPage(page);
        try {
            const res = await axios({
                method: "get",
                url: `${baseURL}/inventory/wastereport?page=${page}&limit=${limit}${search ? "&search=" + search : ""}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (res.message) {
                history.push("/logout");
            } else {
                setSettingPrinterZoneData(res.data.data.map((data) => ({
                    id: data.id,
                    item: data.name,
                    amount: data.amount,
                    unit: data.unit,
                    created_at: data.created_at,
                    reason: data.reason
                })));
                const totalPage = Math.ceil(res.data.total / res.data.limit);
                setTotalPage(totalPage);
            }
        } catch (err) {
            console.error("Error fetching data", err);
            Swal.fire("Error", "There was an error fetching the list.", "error");
        }
    };

    const columns = [
        {
            dataField: "id",
            text: intl.formatMessage({ id: "113271", defaultMessage: "ID" }),
            sort: true,
            headerSortingClasses,
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: "30px" }}>
                        <p>{row.id}</p>
                    </div>
                );
            },
        },
        {
            dataField: "item",
            text: intl.formatMessage({ id: "113138", defaultMessage: "Name" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: "120px" }}>
                        <p>{row.item}</p>
                    </div>
                );
            },
        },
        {
            dataField: "amount",
            // text: "Amount (unit)",
            text: intl.formatMessage({ id: "113238", defaultMessage: "QTY" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: "130px" }}>
                        <p>{row.amount.toFixed(2)} ({row.unit})</p>
                    </div>
                );
            },
        },
        {
            dataField: "reason",
            text: intl.formatMessage({ id: "113224", defaultMessage: "Reason" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: "150px" }}>
                        <p>{row.reason}</p>
                    </div>
                );
            },
        },
        {
            dataField: "created_at",
            text: intl.formatMessage({ id: "112007", defaultMessage: "Created at" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: "160px" }}>
                        <p>{row.created_at}</p>
                    </div>
                );
            },
        },
        // {
        //     text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
        //     dataField: "id",
        //     formatter: (id, row, rowIndex) => {
        //         return (
        //             <div style={{ width: '150px' }}>
        //                 <button
        //                     type="button"
        //                     className="btn btn-primary mr-2"
        //                     onClick={() => {
        //                         editItem({ row, rowIndex })
        //                     }}
        //                 >
        //                     {intl.formatMessage({ id: "113010", defaultMessage: "Edit" })}
        //                 </button>
        //                 <button
        //                     type="button"
        //                     className="btn btn-danger mr-2"
        //                     onClick={() => {
        //                         deleteItem({ id, row, rowIndex })
        //                     }}
        //                 >
        //                     {intl.formatMessage({ id: "113011", defaultMessage: "Delete" })}
        //                 </button>

        //             </div>
        //         );
        //     },
        // },
    ];

    const editItem = (data) => {
        console.log(data)
        history.push("/EditWasteReport", data);
    };

    const deleteItem = async (data) => {
        let token = localStorage.getItem("Token");
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You will not be able to recover this template!",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, keep it",
            });

            if (result.value) {
                await axios.delete(`${baseURL}/inventory/wastereport/${data.row.id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                Swal.fire("Deleted!", "Your item has been deleted.", "success");
                getList(1, limit, search, campaigns_id_headder);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your template is safe :)", "error");
            }
        } catch (err) {
            console.error("Error deleting item", err);
            Swal.fire("Error", "There was an error deleting the item.", "error");
        }
    };

    const handleSearch = () => {
        setPage(1);
        getList(1, limit, search, campaigns_id_headder, dateFrom, dateTo);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        localStorage.setItem("pagePrinterZone", value);
        getList(value, limit, search, campaigns_id_headder, dateFrom, dateTo);
    };

    const handlePageSizeChange = (event) => {
        setLimit(event.target.value);
        getList(page, event.target.value, search, campaigns_id_headder, dateFrom, dateTo);
    };

    const handleDateChange = (field, value) => {
        if (field === "DateFrom") {
            setDateFrom(value)
            setPage(1)
            setLimit(10)
        } else if (field === "DateTo") {
            setDateTo(value)
            setPage(1)
            setLimit(10)
        }
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113396", defaultMessage: "Waste Report" })}></CardHeader>
            <CardBody>
                <div className="d-flex justify-content-between mt-1">
                    <div className="w-50 mr-2 ml-0">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "107002", defaultMessage: "Text Search" })}
                                style={{ width: "100%" }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-50" >
                        <TextField
                            id="DateFrom"
                            type="date"
                            label={intl.formatMessage({ id: "110009", defaultMessage: "Date From" })}
                            defaultValue={dateFrom}
                            style={{
                                height: '38px',
                                width: '185px',
                                backgroundColor: '#F4F6F9',
                                borderRadius: '5px',
                                borderWidth: '0'
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => handleDateChange("DateFrom", e.target.value)}
                        />
                        <TextField
                            id="DateTo"
                            type="date"
                            label={intl.formatMessage({ id: "110010", defaultMessage: "Date To" })}
                            defaultValue={dateTo}
                            style={{
                                height: '38px',
                                width: '185px',
                                backgroundColor: '#F4F6F9',
                                borderRadius: '5px',
                                borderWidth: '0'
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => handleDateChange("DateTo", e.target.value)}
                        />
                        <button
                                type="button"
                                id="button"
                            className="btn btn-primary mt-1 mb-3 ml-3"
                            onClick={() => handleSearch()}
                            >
                            {intl.formatMessage({ id: "110005", defaultMessage: "Search" })}
                        </button>
                    </div>
                </div>
                {settingPrinterZone.length > 0 &&
                    (loading === true ? (
                        <button
                            style={{ width: '150px' }}
                            className="btn btn-primary"
                            type="button"
                            disabled
                        >
                            <span
                                className="spinner-border spinner-border-sm mr-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </button>
                    ) :
                        <button
                            type="button"
                            id="button"
                            className="btn btn-primary"
                            style={{ width: '150px' }}
                            onClick={exportExcel}
                            disabled={loading}
                        >
                        {intl.formatMessage({ id: "113535", defaultMessage: "Export Excel" })}
                        </button>)
                }
                <BootstrapTable
                    keyField="id"
                    data={settingPrinterZone}
                    columns={columns}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                >
                    <PleaseWaitMessage entities={settingPrinterZone} />
                    <NoRecordsFoundMessage entities={settingPrinterZone} />
                </BootstrapTable>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mt-3">
                                {intl.formatMessage({ id: "107032", defaultMessage: "Items per page" })}
                                <select onChange={handlePageSizeChange} value={limit}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Pagination2
                                className="mt-3"
                                count={totalPage}
                                page={page}
                                siblingCount={1}
                                boundaryCount={1}
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};