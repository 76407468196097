import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../../../service/API.js";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";

export const CreateInventory = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113201", defaultMessage: "BOM" })
  );
  const history = useHistory();
  const [data, setData] = useState([
    {
      material_id: "",
      unit_id: "",
      unit_convert_id: "",
      material: "",
      unit: "",
      convert_unit: "",
      list_unit: [],
      material_qty: 0,
    },
  ]);
  const [test, setTest] = useState([]);
  const [titleEN, setTitleEN] = useState("");
  const [role, setRole] = useState("");
  const [listUnit, setListUnit] = useState([]);
  const [listUnitMat, setListUnitMat] = useState([]);
  const [listUnitConvert, setListUnitConvert] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [listMenu, setListMenu] = useState([]);
  const [listMaterial, setListMaterial] = useState([]);
  // const [menuID, setMenuID] = useState(null)
  const [menuID, setMenuID] = useState("")
  const { user, authToken, accessTokenPrivilege } = useSelector(
    (state) => state.auth
  );
  const [listGroup2, setListGroup2] = useState([]);
  const [typeList, setTypeList] = useState([
    "VIEW",
    "CREATE",
    "EDIT",
    "DELETE",
    "IMPORT",
    "EXPORT",
    "ADMIN",
  ]);

  const setMapData = (data) => {
    let temp = [];
    setData(data);
    setTest(data);
  };

  const getListMaterial = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material?page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListMaterial(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getListMenu = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/menu-for-bom?limit=10000&page=1`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListMenu(res.data.data,);
        }
      })
      .catch((err) => {});
  };

  const getListMenuSearch = async (search) => {
    let str = `&${`search=${search}`}`
    if (!search.trim()) {
      str = ''
    }
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/menu-for-bom?limit=10000&page=1${str}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListMenu(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getListUnit = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material_unit?time=1&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListUnit(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getListUnitConvert = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material_unit_convert?time=1&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListUnitConvert(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const updateFieldChanged = (index, value, name) => {
    // console.log('data in updateFieldChanged ==>', data)
    let newArr = [...data];

    newArr[index][name] = value;

    // console.log('data in updateFieldChanged', newArr)
    setData(newArr);
  };

  const handleMenuChange = (newValue) => {
    if (newValue) {
      // updateFieldChanged(0, newValue.id, "menu_id"); // สมมติว่าเมนูอยู่ที่ index 0
      setMenuID(newValue.id);
    } else {
      // updateFieldChanged(0, null, "menu_id"); // รีเซ็ตค่าเมื่อเมนูถูกลบ
      setMenuID("");
    }
  };

  const updateSelectUnitAndUnitConvert = (index, value) => {
    setIsLoading(true)
    // console.log('value in updateSelectUnitAndUnitConvert ==> ', value);
    // console.log('listMaterial in updateSelectUnitAndUnitConvert  ==> ', listMaterial);

    let material = value

    let main = listUnit.filter(x => x.id == value.material_unit_id)
    // console.log('main in updateSelectUnitAndUnitConvert ==>', main[0])

    let sub = listUnitConvert.filter(x => x.id == value.unit_convert_id)
    // console.log("sub in updateSelectUnitAndUnitConvert ==>", sub[0])

    const selectedUnitConvertList = [
      { id: sub[0].main_unit.id, name: sub[0].main_unit.name },
      { id: sub[0].sub_unit.id, name: sub[0].sub_unit.name }
    ]

    // console.log('data in updateSelectUnitAndUnitConvert ==>', data)
    let newArr = [...data];

    newArr[index]['unit_id'] = value.material_unit_id;
    newArr[index]['unit_convert_id'] = value.unit_convert_id;
    newArr[index]['material_id'] = material.id;
    newArr[index]['material'] = material;
    newArr[index]['unit'] = main[0];
    newArr[index]['unit_convert'] = sub[0];
    newArr[index]['list_unit'] = selectedUnitConvertList;

    // console.log('data in updateSelectUnitAndUnitConvert ==>', newArr)
    setData(newArr);
  }

  // useEffect(() => {
  //   console.log("listUnit =>", listUnit)
  //   console.log("listUnitMat =>", listUnitMat)
  //   console.log("listUnitConvert =>", listUnitConvert)
  //   console.log("listMaterial =>", listMaterial)

  // }, [listUnit, listUnitMat, listMaterial, listUnitConvert]);

  // useEffect(() => {

  // }, []);

  // useEffect(() => {
  //   setData(prev => prev)
  // }, [data]);

  useEffect(() => {
    setData(prev => prev)
    setData(data)
    console.log('re-render', data);
    setIsLoading(false)
  }, [data]);

  useEffect(() => {
    getListMaterial();
    getListMenu();
    getListUnit()
    getListUnitConvert()
    // get Material // get หน่วย // อาจมี get ร้าน // get menu
    // setMapData([
    //   {
    //     material_id: "",
    //     unit_convert_id: "",
    //     unit_id: "",
    //     material_qty: 0,
    //   },
    // ]);
  }, []);

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  //'TEXTBOX','DDL','CHECKBOX','IMAGE'

//   const onSubmit = async () => {
//   console.log("data", data);

//   // ตรวจสอบข้อมูลก่อนส่ง
//   let shop_id = localStorage.getItem("shop_id");
//   let branch_id = localStorage.getItem("branch_id");
//   let temp = [];

//   for (let i in data) {
//     if (data[i].material_id == undefined || data[i].material_id == null) {
//       return AlertError(`Please insert material at ${Number(i) + 1}`);
//     }
//     if (data[i].material_qty == [] || data[i].material_qty == 0) {
//       return AlertError(`Please insert material quantity at ${Number(i) + 1}`);
//     }

//     temp.push({
//       material_id: data[i].material_id,
//       unit_convert_id: data[i].unit_convert_id,
//       material_qty: data[i].material_qty,
//       unit_id: data[i].unit_id,
//     });
//   }

//   // สร้าง payload สำหรับ API
//   const events = {
//     menu_id: menuID,
//     material_detail: temp,
//     shop_id: shop_id,
//     branch_id: branch_id,
//   };

//   const apiUrls = `${baseURL}/management/stock/item_material`;
//   const token = localStorage.getItem("Token");

//   // แสดง popup ยืนยัน
//   const result = await Swal.fire({
//     title: "Confirm Create",
//     text: "Are you sure you want to create BOM?",
//     icon: "question",
//     showCancelButton: true,
//     confirmButtonColor: "#3085d6",
//     cancelButtonColor: "#d33",
//     confirmButtonText: "Confirm",
//     cancelButtonText: "Cancel",
//   });

//   // ตรวจสอบว่าได้กดยืนยันหรือไม่
//   if (!result.isConfirmed) return;

//   try {
//     // แสดง Loading popup
//     Swal.fire({
//       title: "Submitting...",
//       allowOutsideClick: false,
//       didOpen: () => {
//         Swal.showLoading();
//       },
//     });

//     // เรียก API
//     await axios({
//       method: "post",
//       url: apiUrls,
//       headers: {
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       data: events,
//     });

//     // สำเร็จ: ปิด popup และเปลี่ยนหน้า
//     Swal.fire("Success", "BOM has been created successfully!", "success");
//     history.goBack();
//   } catch (err) {
//     // แสดงข้อผิดพลาด
//     Swal.fire("Error", `Failed to create BOM: ${err.message}`, "error");
//   }
// };

  const onSubmit = async () => {
    console.log("data in onSubmit =>", data);

    let shop_id = localStorage.getItem('shop_id')
    let branch_id = localStorage.getItem('branch_id')

    if (!shop_id || !branch_id) {
      return AlertError("Shop ID or Branch ID is missing.");
    }

    let temp = [];

    for (let i in data) {
      if (!data[i].material_id) {
        return AlertError(
          `Please insert material at ${Number(i) + 1}`
        );
      }
      if (!data[i].material_qty || isNaN(data[i].material_qty) || data[i].material_qty <= 0) {
        return AlertError(
          `Please insert a valid  material quantity at ${Number(i) + 1}`
        );
      }
      if (!data[i].unit_id) {
        return AlertError(
          `Please insert unit at ${Number(i) + 1}`
        );
      }

      temp.push({
        material_id: data[i].material_id,
        unit_convert_id: data[i].unit_convert_id,
        material_qty: data[i].material_qty,
        unit_id: data[i].unit_id,
      });
      console.log(`temp in forLoopIn, i = ${i} =>`, temp);
    }

    const events = {
        menu_id: menuID,
        material_detail: temp,
        shop_id: shop_id,
        branch_id: branch_id,
      };

    console.log("events =>", events);

    let apiUrls = `${baseURL}/management/stock/item_material`;
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: "",
      text: "Confirm Create",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
      // preConfirm: async () => {
      //     try {
      //       await axios({
      //         method: "post",
      //         url: apiUrls,
      //         headers: {
      //           Accept: "application/json",
      //           Authorization: `Bearer ${token}`,
      //         },
      //         data: events,
      //       });
      //       history.goBack(); // กลับไปยังหน้าก่อนหน้า
      //     } catch (err) {
      //       Swal.showValidationMessage(`Request failed: ${err.message}`);
      //     }
      //   },
      //   allowOutsideClick: () => !Swal.isLoading(), // อนุญาตให้คลิกข้างนอกระหว่างโหลด
      // });
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: events,
        })
          .then(async (res) => {
            history.goBack();
          })
          .catch((err) => {
            Swal.fire("Create !", "Your can not create. !! " + err, "error");
          });
      }
    });

    return false;
  };

  const addPermission = () => {
    let temp =
    // {
    //   material_id: "",
    //   unit_convert_id: "null",
    //   material_qty: 0,
    // };
    {
      unit_id: "",
      unit_convert_id: "",
      material: "",
      unit: "",
      convert_unit: "",
      list_unit: [],
      material_id: "",
      material_qty: 0,
    };
    setData((data) => [...data, temp]);
  };

  const DeleteQuestion = (index) => {
    var array = [...data]; // make a separate copy of the array
    array.splice(index, 1);
    setData(array);
  };

  if(isLoading){
    return <p>Loading...</p>
  }

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: "113195",
          defaultMessage: "Create BOM",
        })}
      >
        <CardHeaderToolbar>
          {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {data.length == 0 ? (
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={() => {
              addPermission();
            }}
          >
            {intl.formatMessage({ id: "113273", defaultMessage: "ADD" })}
          </button>
        ) : null}
        {/* Select Menu Start */}
        <div className="form-inline" style={{ marginBottom: "25px" }}>
          <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            {intl.formatMessage({
              id: "113196",
              defaultMessage: "Select Menu",
            })}
            <span style={{ color: "red" }}>*</span>:{" "}
          </p>
          <Autocomplete
            id="grouped-demo"
            options={listMenu}
            disableClearable={!menuID}
            value={listMenu.find((menu) => menu.id == menuID) || ""}
            //value={[]}
            getOptionLabel={(option) => option.item_name || ""}
            onInputChange={(event, newInputValue) => getListMenuSearch(newInputValue)}
            getOptionSelected={(option, value) => option.id == value.id}
            onChange={(event, newValue) => {
              console.log("newValue in onChange Menu ==>", newValue);
              // handleMenuChange(newValue);
              if (newValue) {
                setMenuID(newValue.id);
              } else {
                setMenuID("");
              }
            }}
            style={{
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            renderInput={(params) => (
              <TextField {...params}
                variant="outlined"
                placeholder="Please select menu"
              />
            )}
          />
        </div>
        {/* Select Menu End */}
        {/* Select all Bom Material Start */}
        {data.map((item, index) => (
          <Card className="Small shadow" key={index}>
            <CardHeader title={index + 1}></CardHeader>
            <CardBody>
              {/* Select Material Start */}
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  {intl.formatMessage({
                    id: "113197",
                    defaultMessage: "Select Material",
                  })}
                  <span style={{ color: "red" }}>*</span>:{" "}
                </p>
                <Autocomplete
                  id="grouped-demo"
                  options={listMaterial}
                  disableClearable={!item.material}
                  value={item.material || ""}
                  getOptionLabel={(option) => option ? option.name : ""}
                  getOptionSelected={(option, value) => option.id == value.id}
                  onChange={(event, newValue) => {
                    // console.log("newValue in material ==>", newValue);
                    if (newValue) {
                      updateSelectUnitAndUnitConvert(index, newValue);
                    } else {
                      updateFieldChanged(index, "", "unit_id");
                      updateFieldChanged(index, "", "unit_convert_id");
                      updateFieldChanged(index, "", "material_id");
                      updateFieldChanged(index, "", "material");
                      updateFieldChanged(index, "", "unit");
                      updateFieldChanged(index, "", "unit_convert");
                      updateFieldChanged(index, [], "list_unit");
                    }
                  }}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                      variant="outlined"
                      placeholder="Please select material"
                    />
                  )}
                />
              </div>
              {/* Select Material End */}
              {/* Quantity Start */}
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  <span style={{ color: "red" }}></span>
                  {intl.formatMessage({
                    id: "113221",
                    defaultMessage: "Quantity",
                  })}
                  <span style={{ color: "red" }}>*</span>:{" "}
                </p>
                <input
                  name="modelGroup"
                  type="number"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={item.material_qty}
                  onChange={(e) => {
                    updateFieldChanged(index, e.target.value, "material_qty");
                  }}
                  min={0}
                />
              </div>
              {/* Quantity End */}
              {/* Select Unit Start*/}
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  {intl.formatMessage({
                    id: "113199",
                    defaultMessage: "Select Unit",
                  })}
                  <span style={{ color: "red" }}>*</span>:{" "}
                </p>
                <Autocomplete
                  id="grouped-demo"
                  disabled={!item.material}
                  options={item.list_unit}
                  disableClearable={!item.unit_id}
                  value={listUnit.find((unit) => unit.id === item.unit_id) || ""}
                  getOptionLabel={(option) => option.name || ""}
                  getOptionSelected={(option, value) => option.id == value.id}
                  onChange={(event, newValue) => {
                    console.log("newValue in unit ==>", newValue);
                    if (newValue) {
                      updateFieldChanged(index, newValue.id, "unit_id");
                    } else {
                      updateFieldChanged(index, "", "unit_id");
                    }
                  }}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                      variant="outlined"
                      placeholder="Please select unit"
                    />
                  )}
                />
              </div>
              {/* Select Unit End*/}
              {/* Convert Unit Start */}
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  {/* {intl.formatMessage({
                    id: "113233",
                    defaultMessage: "Select Convert Unit",
                  })} */}
                  Convert Unit
                  <span style={{ color: "red" }}>*</span>:{" "}
                </p>
                <Autocomplete
                  freeSolo
                  disabled
                  id="grouped-demo"
                  options={listUnitConvert}
                  getOptionLabel={(option) => option ? `${option.main_unit ? option.main_unit.name : ''}-${option.sub_unit ? option.sub_unit.name : ''}` : ""}
                  value={item.unit_convert}
                  getOptionSelected={(option, value) => option == value}
                  onChange={(event, newValue) => {
                    console.log("newValue in unit convert ==>", newValue);
                    updateFieldChanged(index, newValue, "unit_convert");
                    updateFieldChanged(index, newValue.id, "unit_convert_id");
                  }}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                      variant="outlined"
                      placeholder="Please select material"
                    />
                  )}
                />
              </div>
              {/* Convert Unit End */}
              <CardFooter>
                <CardHeaderToolbar>
                  <button
                    type="button"
                    className="btn btn-danger mr-2"
                    onClick={() => {
                      DeleteQuestion(index);
                    }}
                  >
                    {intl.formatMessage({
                      id: "113011",
                      defaultMessage: "DELETE",
                    })}
                  </button>

                  {index == data.length - 1 ? (
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      onClick={() => {
                        addPermission();
                      }}
                    >
                      {intl.formatMessage({
                        id: "113273",
                        defaultMessage: "ADD",
                      })}
                    </button>
                  ) : null}
                </CardHeaderToolbar>
              </CardFooter>
            </CardBody>
          </Card>
        ))}
        {/* Select all Bom Material End */}
        {/* Cancel and Save Start */}
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          ></div>
          <Link to="/Inventory">
            <button type="button" className="btn btn-secondary wbt mr-5">
              {intl.formatMessage({ id: "113038", defaultMessage: "Cancel" })}
            </button>
          </Link>
          <button className="btn btn-primary" onClick={() => onSubmit()}>
            {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
          </button>
        </div>
        {/* Cancel and Save End */}
      </CardBody>
    </Card>
  );
};
