import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";
import { temp_store } from "../../../modules/function/temp_store"

export const EditPurchaseRequest = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113383" ,defaultMessage:"Purchase Request"})
  );
  const history = useHistory();
  const [printer_zone_name, setprinter_zone_name] = useState();
  const [printer_zone_name_en, setprinter_zone_name_en] = useState();
  const [state, setState] = useState(true);
  const [id, setId] = useState();
  const [shopList, setShopList] = useState([]);
  const [itemList, setitemList] = useState([]);
  const [shop, setShop] = useState(null);
  const [shopShow, setShopShow] = useState(null);
  const [branch, setBranch] = useState(null);
  const [item, setItem] = useState(null);
  const [listData, setListData] = useState([]);
  const [quantity, setQty] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [maxIndex, setMaxIndex] = useState(0);
  const [description, setDescription] = useState('')
  const [price_per_unit, setPricePerUnit] = useState(null);
  const [plan , setPlan] = useState(null);
  const [planList, setPlanList] = useState([]);

  const [listMaterial, setListMaterial] = useState([]);
  const [listUnit, setListUnit] = useState([]);
  const [unit, setUnit] = useState(null)

  const getListMaterial = async () => {
    let token = localStorage.getItem("Token");
    try {
      const res = await axios({
        method: "get",
        url: `${baseURL}/inventory/material?search=&page=1&limit=1000`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data && res.data.data) {
        setListMaterial(res.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getListMaterial();
  }, []);

  const handleChangeItem = (event, values) => {
    setItem(values);
    if (values) {
      // แปลง major_unit และ minor_unit จาก item ที่เลือก
      const units = [
        { id: values.major_unit_id, name: values.major_unit_name },
        { id: values.minor_unit_id, name: values.minor_unit_name },
      ];
      setListUnit(units);
      setUnit(null); // ล้างค่า unit เมื่อเปลี่ยน item
    } else {
      setListUnit([]);
      setUnit(null);
    }
  };

  const handleChangeUnit = (event, values) => {
    setUnit(values);
  };

  useEffect(() => {
    gettingShop();
    gettingData();
    gettingItem();
    gettingPlanList();
  }, []);

  const handleChangePlan = (event, values) => {
    console.log(values.id, values);
    if (values) {
      setPlan({id:values.id, name: values.name});
      setListData([]);
      setMaxIndex(0);
      getPlanItems(values.id);
    } else {
      setItem(null);
      setPlan(null);
      setListData([]);
      setMaxIndex(0); 
    }
  };

  const getPlanItems = async (id) => {
    let token = localStorage.getItem("Token");
    try {
      const res = await axios({
        method: "get",
        url: `${baseURL}/purchase/plan?id=${id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.message) {
        console.error(res.message);
        return;
      }

      const newMaterials = res.data.data.map((material, index) => ({
        id: maxIndex + index + 1,
        name: material.name,
        material_id: material.material_id,
        quantity: material.quantity,
        unit_id: material.unit_id || "",
        unit_name: material.unit_name || "",
        price_per_unit: material.price_per_unit,
      }));

      setListData((prevData) => [...prevData, ...newMaterials]);
      setMaxIndex(maxIndex + newMaterials.length);
      setQty("");
      setItem(null);
      setUnit("");
      setPricePerUnit("");
      setIsAdd(true);
    } catch (err) {
      console.error("Error fetching plan items:", err);
    }
  };

  const columns = [
    {
      dataField: "material_id",
      text: intl.formatMessage({ id: "113271", defaultMessage: "ID" }),
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "113138", defaultMessage: "Name" }),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "113165", defaultMessage: "Quantity" }),
    },
    {
      dataField: "unit_name",
      text: intl.formatMessage({ id: "113218", defaultMessage: "unit" }),
    },
    {
      dataField: "price_per_unit",
      text: intl.formatMessage({ id: "113217", defaultMessage: "price/unit (Baht)" }),
    },
    {
      text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
      dataField: "action",
      // formatter: (id, row, rowIndex) => {
      formatter: (id, row) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              // onClick={() => deleteItem(rowIndex)}
              onClick={() => deleteItem(row.id)}
            >
              {intl.formatMessage({ id: "107011", defaultMessage: "delete" })}
            </button>
          </div>
        );
      },
    },
  ];

  const deleteItem = (id) => {
    console.log("Before Deletion:", listData);
    setListData((prevData) => {
      const updatedList = prevData.filter((item) => item.id !== id);
      console.log("After Deletion:", updatedList);
      return updatedList;
    });
  };

  const gettingShop = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/shopGroup`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setShopList(res.data.data);
    });
  };

  const gettingPlanList = async () => {
    await axios({
      method: "get",
      url: `${baseURL}/purchase/plan`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setPlanList(res.data.data)
    });
  };

  //// (1)เมื่อกด edit จากหน้า PR นำข้อมูล items ของ PR มาแสดงในตาราง (1) ////
  const gettingData = async () => {
    await axios({
      method: "get",
      url: `${baseURL}/purchase/pr/${temp_store(props).location.state.row.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(`//// pr data ////`, res.data.data);
      setDescription(res.data.data?.pr[0]?.description)
      // setListData(res.data.data?.list)
      // setId(res.data.data?.pr[0]?.id)

      const newMaterials = res.data.data.list.map((material, index) => ({
        id: maxIndex + index + 1,
        name: material.name,
        material_id: material.item_id,
        quantity: material.quantity,
        unit_id: material.unit_id || "",
        unit_name: material.unit_name || "",
        price_per_unit: material.price_per_unit,
      }));

      setListData((prevData) => [...prevData, ...newMaterials]);
      setMaxIndex(maxIndex + newMaterials.length);
    });
  };

  const gettingItem = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/purchase/pr/item_list`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setitemList(res.data.data);
    });
  };

  const handleAdd = () => {
    if (!item || !quantity || !unit || !price_per_unit) {
      Swal.fire("Error", "Please select an item, quantity, unit and price per unit before adding.", "error");
      return;
    }

    const newItem = {
      id: maxIndex + 1,
      name: item.name,
      material_id: item.id,
      quantity: quantity,
      unit_id: unit.id,
      unit_name: unit.name,
      price_per_unit: price_per_unit,
    };

    setListData((prevData) => [...prevData, newItem]);
    setMaxIndex(maxIndex + 1);
    setQty("");
    setItem(null);
    setUnit("");
    setIsAdd(true);
    setPricePerUnit("")
  };

  useEffect(() => {
    console.log("Updated listData:", listData);
  }, [listData]);

  const handleSend = async () => {
    /*if (description === undefined || description === "") {
      return AlertError("Please insert description !!");
    }*/

    var events = {
      description: description == '' ? 'none' : description,
      list: listData,
      id: temp_store(props).location.state.row.id
  }

    let apiUrls = `${baseURL}/purchase/pr/`;
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Edit PR success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not Edit PR. !! " + err,
          "error"
        );
      });
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113387",
            defaultMessage: "Edit Purchase Request",
          })}
        ></CardHeader>
        <CardBody>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113397",
                  defaultMessage: "Purchase Plan",
                })}
                :{" "}
              </p>
              {/* <div> */}
              <Autocomplete
                id="purchase_plan"
                options={planList}
                value={plan}
                getOptionLabel={(option) =>
                  option.name ? option.name : "ไม่มีชื่อ"
                }
                style={{
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  // borderWidth: "0",
                }}

                onChange={handleChangePlan}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
              {/* </div> */}
            </div>
          </div>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113169" ,defaultMessage:"Description" })}:{" "}
              </p>
              <input
                name="description"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={description}
                onChange={(table_name) => setDescription(table_name.target.value)}
              />
            </div>
          </div>
          <hr></hr>

          <div className="container pt-3">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113166",
                  defaultMessage: "Item",
                })}
                :{" "}
              </p>
              <div>
                <Autocomplete
                  id="grouped-unit"
                  disableClearable
                  // options={itemList}
                  options={listMaterial}
                  value={item}
                  getOptionLabel={(option) =>
                    option.name ? option.name : "ไม่มีชื่อ"
                  }
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                  }}
                  onChange={handleChangeItem}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113165",
                  defaultMessage: "Amount",
                })}
                :{" "}
              </p>
              <input
                value={quantity}
                type="number"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(e) =>
                  setQty(e.target.value)
                }
              />
            </div>

          </div>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113218",
                  defaultMessage: "Unit",
                })}
                :{" "}
              </p>
              <Autocomplete
                id="grouped-unit"
                options={listUnit}
                disableClearable
                getOptionLabel={(option) => `${option.name}`}
                value={unit ? { name: unit.name } : null}
                style={{
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={handleChangeUnit}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div>
          </div>

          <div className="container">
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113400",
                  defaultMessage: "Price per Unit",
                })}
                :{" "}
              </p>
              <input
                value={price_per_unit}
                type="number"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(e) =>
                  setPricePerUnit(e.target.value)
                }
              />
              {/* <button
                className="ml-2 btn btn-primary"
                onClick={() => handleAdd()}
              >
                {intl.formatMessage({ id: "113167", defaultMessage: "Add" })}
              </button> */}
            </div>
          </div>

          <div className="container">
            <div className="form-inline d-flex justify-content-center"
            >
              <button
                className="ml-2 btn btn-primary"
                onClick={handleAdd}
              >
                {intl.formatMessage({ id: "113167", defaultMessage: "Add" })}
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <BootstrapTable
            keyField="item_id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div className="container mt-3">
              <div className="form-inline d-flex justify-content-center">
                <Link to="/PurchaseRequest">
                  <button
                    type="button"
                    className="btn btn-secondary wbt"
                  // aria-label={intl.formatMessage({ id: "107016", defaultMessage: "Cancel" })}
                  >
                    {intl.formatMessage({
                      id: "107016",
                      defaultMessage: "Cancel",
                    })}
                  </button>
                </Link>
                <button className="btn btn-primary" onClick={() => handleSend()}>
                  {intl.formatMessage({ id: "107017", defaultMessage: "Save" })}
                </button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
