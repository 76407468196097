import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useIntl } from "react-intl";
import { Button } from "react-bootstrap"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },

    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export const CreateMenuGroupV3 = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    console.log('props CreateMenuGroup', props);
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({ id: "103001", defaultMessage: "Display menu type" }));
    const history = useHistory();
    const [group_name, setGroup_name] = useState("");
    const [group_name_en, setGroup_name_en] = useState("");
    const [group_name_cn, setGroup_name_cn] = useState("");
    const [group_name_jp, setGroup_name_jp] = useState("");
    const [group_name_kr, setGroup_name_kr] = useState("");
    const [group_name_id, setGroup_name_id] = useState("");
    const [enable_cn, setEnable_cn] = useState(false);

    const [state, setState] = useState(true);
    const [displayOrder, setDisplayOrder] = useState(0)
    const [type, setType] = useState('component')
    const [visibleOnlyEmployee, setVisibleOnlyEmployee] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const initialFilteredZone = location.state?.filteredZone;
    const propsFilteredZone = props?.state;
    const [filteredZone, setFilteredZone] = useState(() => {
        return initialFilteredZone ?? propsFilteredZone ?? [];
      });
    const [selectedZone, setSelectedZone] = useState([]);

    useEffect(() => {
        if (initialFilteredZone !== undefined) {
          setFilteredZone(initialFilteredZone);
        } else if (propsFilteredZone !== undefined) {
          setFilteredZone(propsFilteredZone);
        }
      }, [initialFilteredZone, propsFilteredZone]);

    useEffect(() => {
        if (props.type === 'dialog') {
            setType(props.type);
        }
        checkEnableCN();
    }, []);

    const checkEnableCN = () => {
        let temp = localStorage.getItem('is_enable_cn')
        if (temp == '1') {
            setEnable_cn(true)
        }
    }

    const AlertError = (message) => {
        Swal.fire("Menu Group!", message, "error");
        setIsLoading(false)
    };

    const handleChangeSwitch = () => {
        setState(!state);
    };

    const handleVisibility = () => {
        setVisibleOnlyEmployee(!visibleOnlyEmployee)
    }

    const onSubmit = async () => {

        if (group_name === undefined || group_name === "") {
            return AlertError("please insert name th.");
        }

        // if (group_name_en === undefined || group_name_en === "") {
        //   return AlertError("please insert name en.");
        // }
        if (displayOrder === undefined || displayOrder === '' || isNaN(displayOrder) || Number(displayOrder) === 0 || Number(displayOrder) < 0) {
            return AlertError('please insert display order.')
        }

        let get_shop_id = localStorage.getItem("shop_id");
        let get_branch_id = localStorage.getItem("branch_id");

        var events = {
            type: "GROUP_MENU",
            shop_id: Number(get_shop_id),
            branch_id: Number(get_branch_id),
            group_name,
            group_name_en: group_name_en ? group_name_en : null,
            group_name_cn: group_name_cn ? group_name_cn : null,
            group_name_jp: group_name_jp ? group_name_jp : null,
            group_name_kr: group_name_kr ? group_name_kr : null,
            group_name_id: group_name_id ? group_name_id : null,
            enabled: state,
            display_order: displayOrder === 0 ? 0 : displayOrder,
            is_view_only_employee: Boolean(visibleOnlyEmployee),
            visible_zone: selectedZone.map(zone => zone.id),
        };

        let apiUrls = `${baseURL}/management/group`;
        let token = localStorage.getItem("Token");
        const resultAll = axios({
            method: "post",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: events,
        })
            .then(async (res) => {
                if (type == 'dialog') {
                    props.handle('save')
                } else {
                    history.goBack();
                }
            })
            .catch((err) => {
                Swal.fire(
                    "Create !",
                    "You can not create group. !! " + err,
                    "error"
                );
            });
    };

    const cancelItem = (data) => {
        if (type == 'dialog') {
            props.handle('close')
        } else {
            history.goBack();
        }
    };

    // const handleChangezoneprinter = (event, values) => {
    //   if (values) {
    //     setzoneprinter_id(values.id);
    //   } else {
    //     setzoneprinter_id("");
    //   }
    // };

    const handleTranslate = async () => {
        setIsLoading(true)

        if (!group_name || group_name.trim() === "") {
            return AlertError("please insert group name.");
        }

        // setOpenTranslate(true)

        try {
            const events = { item_name: group_name }
            let apiUrls = `${baseURL}/translate`;
            let token = localStorage.getItem("Token");

            const res = await axios({
                method: "post",
                url: apiUrls,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                data: events,
            })

            console.log(res.data)
            setGroup_name_en(res.data.data?.item_name.translations.en)
            setGroup_name_cn(res.data.data?.item_name.translations.zh)
            setGroup_name_jp(res.data.data?.item_name.translations.ja)
            setGroup_name_kr(res.data.data?.item_name.translations.ko)
            setGroup_name_id(res.data.data?.item_name.translations.hi)

        } catch (error) {
            console.log(error.system_response.message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Card>
                <CardHeader title={intl.formatMessage({ id: "103012", defaultMessage: "Create a group menu" })}></CardHeader>
                <CardBody>
                    {/* Thai name  */}
                    <div className="form-inline" >
                        <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "103013", defaultMessage: "Thai name" })}:{" "}
                        </p>
                        <input
                            name="group_name"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(e) => setGroup_name(e.target.value)}
                        />
                    </div>
                    {/* Thai name  */}
                    {/* Translation Button  */}
                    {/* <div
            className="py-2"
          >
            <Button
              variant={isLoading ? "secondary" : "primary"}
              disabled={isLoading}
              className="btn btn-outline-primary btn-lg"
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
              }}
              onClick={handleTranslate}
            >
              Translate
              {isLoading && <span className="ml-3 spinner spinner-white"></span>}
            </Button>
          </div> */}
                    {/* Translation Button  */}
                    {/* English name  */}
                    {/* <div className="form-inline" style={{ marginBottom: "25px" }}> */}
                    <div className="form-inline" >
                        <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
                            {intl.formatMessage({ id: "103014", defaultMessage: "english name" })}:{" "}
                        </p>
                        <input
                            name="group_name_en"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(e) => setGroup_name_en(e.target.value)}
                            value={group_name_en}
                        />
                    </div>
                    {/* English name  */}

                    {/* More language Start */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <Accordion style={{
                            marginTop: "15px",
                            width: "100%",
                            borderRadius: "10px",
                            borderWidth: "0",
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>
                                    {intl.formatMessage({ id: "113523", defaultMessage: "More languages" })}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="container" style={{ border: "1px solid", borderRadius: "10px", borderColor: "#CECDCD", paggingBottom: "20px", paddingTop: "20px" }}>
                                    {/* Chinese name  */}
                                    <div className="form-inline " >
                                        <p style={{ marginBottom: "15px", float: "left", width: "20%", minWidth: "120px" }}>
                                            {intl.formatMessage({ id: "103126", defaultMessage: "chinese name" })}:{" "}
                                        </p>
                                    </div>
                                    <div className="form-inline" >
                                        <input
                                            name="group_name_cn"
                                            value={group_name_cn}
                                            style={{
                                                marginBottom: "15px",
                                                height: "38px",
                                                width: "530px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setGroup_name_cn(e.target.value)}
                                        />
                                    </div>
                                    {/* Chinese name  */}
                                    {/* Japanese name  */}
                                    {/* <div className="form-inline mt-3" >
                                        <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
                                            {intl.formatMessage({ id: "113508", defaultMessage: "Japanese name" })}:{" "}
                                        </p>
                                    </div>
                                    <div className="form-inline" >
                                        <input
                                            name="group_name_jp"
                                            value={group_name_jp}
                                            style={{
                                                height: "38px",
                                                width: "530px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setGroup_name_jp(e.target.value)}
                                        />
                                    </div> */}
                                    {/* Japanese name  */}
                                    {/* Korean name  */}
                                    {/* <div className="form-inline mt-3" >
                                        <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
                                            {intl.formatMessage({ id: "113509", defaultMessage: "Korean name" })}:{" "}
                                        </p>
                                    </div>
                                    <div className="form-inline" >
                                        <input
                                            name="group_name_kr"
                                            value={group_name_kr}
                                            style={{
                                                height: "38px",
                                                width: "530px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setGroup_name_kr(e.target.value)}
                                        />
                                    </div> */}
                                    {/* Korean name  */}
                                    {/* Indian name  */}
                                    {/* <div className="form-inline mt-3" >
                                        <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
                                            {intl.formatMessage({ id: "113510", defaultMessage: "Indian name" })}:{" "}
                                        </p>
                                    </div>
                                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                                        <input
                                            name="group_name_id"
                                            value={group_name_id}
                                            style={{
                                                height: "38px",
                                                width: "530px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setGroup_name_id(e.target.value)}
                                        />
                                    </div> */}
                                    {/* Indian name  */}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>



                    {/* Display order  */}
                    <div className="form-inline" style={{ marginBottom: '25px' }}>
                        <p style={{ marginTop: '15px', float: 'left', width: '20%', minWidth: "120px" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "103015", defaultMessage: "display order" })}:{' '}
                        </p>
                        <input
                            name="displayOrder"
                            type="number"
                            style={{
                                height: '38px',
                                width: '530px',
                                backgroundColor: '#F4F6F9',
                                borderRadius: '5px',
                                borderWidth: '0'
                            }}
                            value={displayOrder}
                            onChange={displayOrder =>
                                setDisplayOrder(displayOrder.target.value)
                            }
                        />
                    </div>
                    {/* Display order  */}


                    {/* <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Zone printer :{" "}
            </p>

            {zoneprinterData.length > 0 ? (
              <div>
                <Autocomplete
                  id="zone printer"
                  options={zoneprinterData}
                  getOptionLabel={(option) => option.name}
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  onChange={handleChangezoneprinter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputColor}
                      style={{
                        borderWidth: 0,
                      }}
                      label="Zone printer"
                    />
                  )}
                />
              </div>
            ) : null}
          </div> */}

                    {/* Enable */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "103016", defaultMessage: "enable" })}:{" "}
                        </p>
                        <Switch
                            checked={state}
                            onChange={() => handleChangeSwitch()}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </div>
                    {/* Enable */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "113513", defaultMessage: "This menu group will be visible only to employee" })}:{" "}
                        </p>
                        <Switch
                            checked={visibleOnlyEmployee}
                            onChange={() => handleVisibility()}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                        {intl.formatMessage({ id: "6", defaultMessage: "Visible Zone" })}:{" "}
                        </p>
                        <Autocomplete
                            multiple
                            id="zone-selector"
                            options={filteredZone} 
                            getOptionLabel={(option) => option.zone_name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={selectedZone}
                            onChange={(event, newValue) => setSelectedZone(newValue)}
                            style={{
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        ></div>
                        <button type="button" className="btn btn-secondary wbt mr-5" onClick={() => cancelItem()}>
                            {intl.formatMessage({ id: "103017", defaultMessage: "Cancel" })}
                        </button>
                        <button className="btn btn-primary" onClick={() => onSubmit()}>
                            {intl.formatMessage({ id: "103018", defaultMessage: "Save" })}
                        </button>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};
