import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "./component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { UOM } from './UOM';
import { Convert } from "./Convert";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useIntl } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination2 from "@material-ui/lab/Pagination";
import {
    sortCaret,
    headerSortingClasses,
} from "../../_metronic/_helpers";
import { Modal, Button } from "react-bootstrap";
import { CreateMenuGroup } from './EditQTY';
import { roleCheck } from '../service/role';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    root_tab: {
        flexGrow: 1,
    },
}));

export const Stock = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { authToken } = useSelector((state) => state.auth);
    const classes = useStyles();
    suhbeader.setTitle(intl.formatMessage({ id: "113206", defaultMessage: "Stock" }));
    const history = useHistory();
    const [name, setname] = useState();
    const [name_en, setname_en] = useState();
    const [description, setdescription] = useState();
    const [description_en, setdescription_en] = useState();
    const [address, setaddress] = useState();
    const [address_en, setaddress_en] = useState();
    const [settingPrinterZone, setSettingPrinterZoneData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalSize, setTotalSize] = useState(100);
    const [search, setSearch] = useState();
    const [campaigns_id_headder, setCampaigns_id_headder] = useState();
    const [show, setShow] = useState(false);
    const [selectID, setSelectID] = useState(0);
    const [qty, setQTY] = useState(0);
    const [roleList, setRoleList] = useState([]);

    const columns = [
        {
            dataField: "id",
            text: intl.formatMessage({ id: "113271", defaultMessage: "ID" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: "90px" }}>
                        <p>{row.id}</p>
                    </div>
                );
            },

        },
        {
            dataField: "name",
            text: intl.formatMessage({ id: "113138", defaultMessage: "Name" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: "130px" }}>
                        <p>{row.name}</p>
                    </div>
                );
            },
        },
        {
            dataField: "major_remaining",
            text: intl.formatMessage({ id: "113220", defaultMessage: "Balance (unit)" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: "130px" }}>
                        <p>{row.major_remaining.toFixed(2)} {row.major_unit_name}</p>
                    </div>
                );
            },
        },
        {
            dataField: "updated_at",
            text: intl.formatMessage({ id: "113142", defaultMessage: "Updated at" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: "150px" }}>
                        <p>{row.updated_at}</p>
                    </div>
                );
            },
        },
        {
            text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
            // dataField: "id",
            dataField: "action",
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{width: '150px'}}>
                        <button
                            type="button"
                            className="btn btn-primary mr-2"
                            onClick={() => {
                                ListItem({ row, rowIndex })
                            }}
                        >
                            {intl.formatMessage({ id: "113160", defaultMessage: "List" })}
                        </button>
                        {roleList.includes('EDIT')&&<button
                            type="button"
                            className="btn btn-primary mr-2"
                            onClick={() => {
                                editItem({ id, row, rowIndex })
                            }}
                        >
                            {intl.formatMessage({ id: "113223", defaultMessage: "Adjust" })}
                        </button>}
                        
                    </div>
                );
            },
        },
    ];
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlePass = (data) => {
        if (data === 'save') {
            getStock(1, limit, search, campaigns_id_headder)
        }
        handleClose()
      }
    const handleSearch = () => {
        setPage(1);
        getStock(1, limit, search, campaigns_id_headder);
    };

    const handlePageChange = (event, value) => {
        console.log(value)
        setPage(value);
        localStorage.setItem("pagePrinterZone", value)
        getStock(value, limit, search, campaigns_id_headder);
    };

    const handlePageSizeChange = (event) => {
        setLimit(event.target.value);
        getStock(1, event.target.value, search, campaigns_id_headder);
    };
    const AlertError = (message) => {
        Swal.fire("Shop!", message, "error");
    };
    useEffect(() => {
        const filted = settingPrinterZone.filter(x => x.reorder_material_unit_id === x.main_unit_id ? x.major_remaining <= x.reorderlevel : x.minor_remaining <= x.reorderlevel);

        if (filted.length > 0) {
            const joinString = filted
                .map(x => `${x.name} ขั้นต่ำ ${x.reorderlevel} ${x.reorder_material_unit_id === x.main_unit_id ? x.major_unit_name : x.minor_unit_name}`)
                .join(" ,<br>");

            Swal.fire({
                title: "Alert Minimum!",
                html: `Please Refill:<br>${joinString}`,
                icon: "error"
            });
        }
    }, [settingPrinterZone]);

    useEffect(() => {
        console.log(`******* settingPrinterZone *******`, settingPrinterZone)
    }, [settingPrinterZone])

    const getStock = async (page, limit, search, campaigns_id_headders) => {
        let allData = []
        console.log(page)
        setPage(page);
        await axios({
            method: "get",
            url: `${baseURL}/inventory?page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
                }`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            if (res.message) {
                history.push("/logout");
            } else {
                res.data.data.map((item, index) => {
                    console.log(item)
                    allData.push(item);

                })
                console.log(res.data.data)
            }

            var totalpage = Math.ceil(res.data.total / res.data.limit);
            setTotalPage(totalpage);
            setTotalSize(res.data.total);
            setLimit(res.data.limit);
            setSettingPrinterZoneData(res.data.data);
        });
    };
    const editItem = (data) => {
        console.log(data)
        history.push("/EditStock", data);
    };
    const ListItem = (data) => {
        history.push("/StockList", data);
    };
    const deleteItem = (data) => {
        let token = localStorage.getItem("Token");
        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this template !",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                axios.delete(`${baseURL}/management/stock/bom/${data.row.posItem.id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }).then((res) => {
                    Swal.fire("Deleted!", "Your template has been deleted.", "success");
                    getStock(1, limit, search, campaigns_id_headder);
                }).catch((err) => {
                    Swal.fire(
                        "Deleted!",
                        "Your template has been fail to deleted.",
                        "error"
                    );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your template is safe :)", "error");
            }
        });
    };

    useEffect(() => {
        
        getShop();
        checkAuth()
    }, []);
    const checkAuth =async()=> {
        const data = await roleCheck('INVENTORY')
        setRoleList(data)
        console.log(data)
        if (!data.includes('VIEW')) {
          history.push('/auth/login')
        }
        let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
        setCampaigns_id_headder(campaigns_id_headders);
        getStock(page, limit, search, campaigns_id_headder)
      }
    const getShop = async () => {
        axios({
            method: "get",
            url: `${baseURL}/management/shop`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            if (res.message) {
                history.push("/logout");
            } else {
                setname(res.data.data.name);
                setname_en(res.data.data.name_en);
                setdescription(res.data.data.description);
                setdescription_en(res.data.data.description_en);
                setaddress(res.data.data.address);
                setaddress_en(res.data.data.address_en);
            }
        });
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card>
            <div className={classes.root_tab}>

                <Card>
                    <CardHeader title={intl.formatMessage({ id: "113206", defaultMessage: "Stock" })}>
                        <CardHeaderToolbar>
                            <Link to="/CreateStock">
                                <button type="button" className="btn btn-primary">
                                    {intl.formatMessage({ id: "107009", defaultMessage: "Create" })}
                                </button>
                            </Link>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <div className="row form-inline mb-2">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={intl.formatMessage({ id: "107002", defaultMessage: "Text Search" })}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        id="button"
                                        className="btn btn-primary"
                                        style={{ width: "100%" }}
                                        onClick={() => handleSearch()}
                                    >
                                        {intl.formatMessage({ id: "107003", defaultMessage: "search" })}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <BootstrapTable
                            keyField="id"
                            data={settingPrinterZone}
                            columns={columns}
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center overflow-hidden"
                            bootstrap4
                        />
                        <div className=" col-lg-12">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mt-3">
                                        {intl.formatMessage({ id: "107032", defaultMessage: "Item per page" })}
                                        <select onChange={handlePageSizeChange} value={limit}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <Pagination2
                                        className="mt-3 "
                                        count={totalPage}
                                        page={page}
                                        siblingCount={1}
                                        boundaryCount={1}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose} animation={false}>
                            <Modal.Body>
                                <CreateMenuGroup id={selectID} qty={qty} handle={handlePass} />
                            </Modal.Body>

                        </Modal>
                    </CardBody>
                </Card>


            </div>


        </Card>
    );
};
